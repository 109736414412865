
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import { useI18n } from "vue-i18n"

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des factures",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n()

    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      searchDefault: "",
    });

    const tableHeader = ref([
      {
        name: 'Projet',
        key: "pro_nom_projet",
        sortable: true,
      },
      {
        name: 'Date',
        key: "tfa_date_creation",
        sortable: true,
      },
      {
        name: 'Echéance',
        key: "tfa_date_echeance",
        sortable: true,
      },
      {
        name: 'Numéro de facture',
        key: "tfa_code",
        sortable: true,
      },
      {
        name: 'Statut',
        key: "tfa_custom_status",
        sortable: true,
      },
      {
        name: 'Total HT',
        key: "tfa_total_ht",
        sortable: true,
      },
      {
        name: 'Solde',
        key: "tfa_solde",
        sortable: true,
      },
      {
        name: 'Afficher',
        key: "tfa_seq",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: 'Demandeurs',
        key: "con_seq",
        label: "con_prenom_nom",
      },
      {
        name: 'Status',
        key: "tfa_custom_status",
        label: "tfa_custom_status",
        default : ["A régler", "Facture échue"],
      },
    ]);

    const ExcelFormat = ref({
      Projet: "pro_nom_projet",
      "Numero commande client": "toc_num_commande_client",
      "Numéro Offre Stillnetwork": "tof_code",
      Date: "tfa_date_creation",
      Demandeur: "con_prenom_nom",
      Statut: "tfa_custom_status",
      "Montants HT": "tfa_total_ht",
      Solde: "tfa_solde",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == t("Réglée")) return "btn-icon-success";
      if (status == t("Facture échue")) return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == t("Réglée")) return "media/icons/duotune/general/gen048.svg";
      if (status == t("Facture échue"))
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    const facturePDF = async (mFact) => {
      state.mFact = mFact;
      const resultAdr = await mAxiosApi.prototype.getAxios("/getFactureGed/" + mFact.tfa_seq);

      const records = resultAdr.records;
      const srcPDF = "data:application/pdf;base64," + records[0].ged_document;
      state.sourcePDF = srcPDF;
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Liste des factures", [
        "Home",
        "Board Stillnetwork",
      ]);

      let myList = await mAxiosApi.prototype.getAxios("/allFactures");
      state.initialMyList = myList.results;
      
      state.myList = myList.results;
      state.loaderEnabled = false;

      if (router.currentRoute.value.params.code) {
        const index = state.myList.findIndex(
          (object: any) =>
            object.tfa_code === router.currentRoute.value.params.code
        );
        state.searchDefault = router.currentRoute.value.params.code + ""
        tableFilters.value.filter(el => el.key=="tfa_custom_status")[0]['default'] = [];
        facturePDF(state.myList[index]);
      }


    });

    return {
      tableHeader,
      state,
      formatDate,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      facturePDF,
      tableFilters,
      ExcelFormat,
    };
  },
});
